<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";

import { getParisProofColorClass } from "@/composables/colors";
import { getBuildingsByToken } from "@/services/api/building.api";
import GridPagination from "@/components/general/GridPagination.vue";
import { formatThousandNoFraction } from "@/composables/formatNumbers";
import useGrid27 from "@/composables/grid27.js";

const emits = defineEmits(["changed-selection"]);
const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging, changePageSize } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  token: String,
  showSelect: { type: Boolean, default: false },
  hideScore: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
});

const _headers = [
  {
    text: "",
    align: "left",
    sortable: false,
    value: "details",
    width: "20",
    showDetails: true,
  },
  {
    text: "Paris Proof Score",
    align: "left",
    sortable: true,
    value: "parisProofScore",
    showScore: true,
  },
  { text: "Referentie", value: "ref" },
  { text: "Omschrijving", value: "descr" },
  { text: "Adres", value: "address" },
  { text: "Plaats", value: "city" },
  { text: "Functie", value: "function" },
  { text: "Oppervlakte", value: "sqm", align: "right" },
  { text: "Datascore", value: "dataScore.score", align: "center", showScore: true },
  { text: "Rol", value: "partyRoleDescr" },
  { text: "Energielabel", value: "label" },
];

const selected = ref([]);

const headers = computed(() => {
  let newHeaders = _headers;
  if (props.hideDetails) {
    newHeaders = newHeaders.filter((x) => !x.showDetails);
  } else {
    newHeaders = newHeaders.filter((x) => !x.showDetails || x.showDetails == true);
  }
  if (props.hideScore) {
    newHeaders = newHeaders.filter((x) => !x.showScore);
  } else {
    newHeaders = newHeaders.filter((x) => !x.showScore || x.showScore == true);
  }

  return newHeaders;
});

watch(
  () => [props.token, props.showSelect],
  () => {
    //console.log("BuildingGrid watch token ", props.showSelect, pageSize);
    if (props.showSelect) {
      changePageSize(1000);
    }
    fetchFirstPage();
  },
  { immediate: true }
);

watch(
  () => selected.value,
  () => {
    console.log("BuildingGrid watch selected ", selected.value);
    emits("changed-selection", {
      selected: selected.value.map((x) => {
        return { id: x.id, description: `${x.ref} (${x.descr})` };
      }),
    });
  }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  if (props.token) {
    console.log("%cBuildingGrid, getData", "background: purple; color: white", props.token);
    const pageNumber0 = pageNumber - 1;
    const response = await getBuildingsByToken(props.token, pageNumber0, pageSize, sortBy, sortDesc);
    return response;
  } else {
    return undefined;
  }
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  console.log("BuildingGrid fetchError", value);
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <!-- server-items-length: noodzakelijk anders werkt sorting niet goed, gaat ie eerst client sorting doen en dan alsnog van server halen -->
    <!-- options.sync: dit werkt niet goed met externe paging, wordt dus alleen gebruikt voor sorting -->
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      class="overview-table"
      @update:options="changedOptions"
      :show-select="showSelect"
      v-model="selected"
      item-key="id"
    >
      <template v-slot:[`item.details`]="{ item }">
        <v-btn icon small color="primary" :to="{ name: 'BuildingDetails', params: { addressId: item.id } }">
          <v-icon small> mdi-eye </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.parisProofScore`]="{ item }">
        <v-layout class="d-flex align-center justify-space-between">
          <span class="icon-parisproof-icon" :class="getParisProofColorClass(item.ppScore, item.ppNorm)"></span>
          <span v-if="item.ppScore">{{ item.ppScore }} kWh/m²</span>
          <span v-else>-</span>
        </v-layout>
      </template>
      <template v-slot:[`item.sqm`]="{ item }">
        <span v-if="item.sqm != null">{{ formatThousandNoFraction(item.sqm) }} m²</span>
      </template>
      <template v-slot:[`item.dataScore.score`]="{ item }">
        <span>{{ formatThousandNoFraction(item.dataScore.score) }}%</span>
      </template>
      <template v-slot:[`item.label`]="{ item }">
        {{ item.energyLabelIsEstimate }}
        <energy-label :label="item.label" :estimate="item.energyLabelIsEstimate" class="small"></energy-label>
      </template>
    </v-data-table>
    <grid-pagination v-if="!showSelect" :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
