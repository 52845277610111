var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-roadmap", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "app-welcome-message" }, [
            _c("h1", [_vm._v("Routekaart")]),
            _c("p", [
              _vm._v(
                "In dit overzicht worden alle maatregelen in de tijd voor uw portefeuille getoond."
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("roadmap-investment-vs-pps", {
                attrs: { token: _setup.tokenDelayed },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("roadmap-co2emission-vs-saving-utility", {
                attrs: { token: _setup.tokenDelayed },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", [
            _c(
              "article",
              { staticClass: "inner" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("header", [_c("h4", [_vm._v("Gebouwen")])]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "overview-filters align-items-start mt-4" },
                  [
                    _c("filter-set", {
                      attrs: {
                        name: _setup.FilterTokenName.Roadmap,
                        filters: _setup.filters,
                      },
                      on: {
                        "filter-search": _setup.handleFilterSearch,
                        "filters-changed27": _setup.handleFiltersChanged,
                      },
                    }),
                  ],
                  1
                ),
                _setup.token
                  ? _c(_setup.BuildingGrid, { attrs: { token: _setup.token } })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }