<script lang="ts" setup>
import { ref, getCurrentInstance } from "vue";
import BuildingGrid from "@/components/app/buildings/BuildingGrid.vue";
import { getBuildingEcmFunctions, getBuildingPartyRoles, getBuildingFilterOptions } from "@/services/api/building.api";
import { getBuildingsFilterToken } from "@/services/api/token.api";
import { FilterTokenName, FilterStatus, mapFilterResponseStatus } from "@/stores/filtertoken";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";
import { labels } from "@/composables/miscellaneous.js";

const { token, tokenDelayed, searchToken, mapFilterResult, createFilterToken, resetFilterToken } = handleFilterTokens27(FilterTokenName.Roadmap);

const filters = ref([
  {
    name: "Referentie",
    fieldName: "locationReference",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Omschrijving",
    fieldName: "descr",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Adres",
    fieldName: "address",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Plaats",
    fieldName: "city",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Functie",
    fieldName: "function",
    type: "list",
    options: [],
  },
  {
    name: "Oppervlakte",
    fieldName: "sqm",
    type: "range",
    options: [],
  },
  {
    name: "Datascore",
    fieldName: "dataScore",
    type: "range",
    options: [],
  },
  {
    name: "Rol",
    fieldName: "partyRole",
    type: "list",
    options: [],
  },
  {
    name: "Energielabel",
    fieldName: "label",
    type: "list",
    options: [...labels],
  },
]);

async function handleFilterSearch(index, text) {
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "partyRole":
      {
        const response = await getBuildingPartyRoles(searchToken.value);
        result = response.data;
      }
      break;

    case "function":
      {
        const response = await getBuildingEcmFunctions(searchToken.value);
        result = response.data;
      }
      break;
    case "label":
      result = filters[index].options;
      break;
    default:
      if (text && text.length > 0) {
        const response = await getBuildingFilterOptions(searchToken.value, filters.value[index].fieldName, text);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function handleFiltersChanged(filterData) {
  if (filterData.length > 0) {
    const getFilterToken = getBuildingsFilterToken(searchToken.value, filterData);
    const success = await createFilterToken(getFilterToken);
    if (!success) {
      proxy.$toaster.error("Ophalen van de filter token is mislukt!");
    }
  } else {
    resetFilterToken();
  }
}
</script>

<template>
  <v-container fluid class="app-roadmap">
    <v-row>
      <v-col class="app-welcome-message">
        <h1>Routekaart</h1>
        <p>In dit overzicht worden alle maatregelen in de tijd voor uw portefeuille getoond.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <roadmap-investment-vs-pps :token="tokenDelayed"></roadmap-investment-vs-pps>
      </v-col>
      <v-col cols="12" md="6">
        <roadmap-co2emission-vs-saving-utility :token="tokenDelayed"></roadmap-co2emission-vs-saving-utility>
      </v-col>
    </v-row>
    <v-row class="overview-wrapper">
      <v-col>
        <article class="inner">
          <v-row>
            <v-col>
              <header>
                <h4>Gebouwen</h4>
              </header>
            </v-col>
          </v-row>

          <div class="overview-filters align-items-start mt-4">
            <filter-set :name="FilterTokenName.Roadmap" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
          </div>

          <building-grid v-if="token" :token="token"></building-grid>
        </article>
      </v-col>
    </v-row>
  </v-container>
</template>
