var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "overview-table",
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
          "show-select": _vm.showSelect,
          "item-key": "id",
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.details`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        small: "",
                        color: "primary",
                        to: {
                          name: "BuildingDetails",
                          params: { addressId: item.id },
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-eye "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.parisProofScore`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-layout",
                    {
                      staticClass: "d-flex align-center justify-space-between",
                    },
                    [
                      _c("span", {
                        staticClass: "icon-parisproof-icon",
                        class: _setup.getParisProofColorClass(
                          item.ppScore,
                          item.ppNorm
                        ),
                      }),
                      item.ppScore
                        ? _c("span", [_vm._v(_vm._s(item.ppScore) + " kWh/m²")])
                        : _c("span", [_vm._v("-")]),
                    ]
                  ),
                ]
              },
            },
            {
              key: `item.sqm`,
              fn: function ({ item }) {
                return [
                  item.sqm != null
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_setup.formatThousandNoFraction(item.sqm)) +
                            " m²"
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.dataScore.score`,
              fn: function ({ item }) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _setup.formatThousandNoFraction(item.dataScore.score)
                      ) + "%"
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.label`,
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(item.energyLabelIsEstimate) + " "),
                  _c("energy-label", {
                    staticClass: "small",
                    attrs: {
                      label: item.label,
                      estimate: item.energyLabelIsEstimate,
                    },
                  }),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _setup.selected,
          callback: function ($$v) {
            _setup.selected = $$v
          },
          expression: "selected",
        },
      }),
      !_vm.showSelect
        ? _c(_setup.GridPagination, {
            attrs: {
              pageNumber: _setup.pageNumber,
              pageSize: _setup.pageSize,
              pageCount: _setup.pageCount,
            },
            on: { changedPaging: _setup.changedPaging },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }